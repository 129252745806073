import { dispatchMenuEntries } from '@/pages/menuEntries/menuEntries.store'
import { type MenuElement } from '@/pages/menuEntries/MenuEntry.type'
import {
  type PageContent,
  type PageElement,
} from '@/pages/menuEntries/pageSetup/pageContent.type'

import { type RootState } from '../../store'
import { createStore, type GettersObj } from '../../utils/createStore'
import { ApplicationDataService } from '../service/applicationDataService'
import { ErrorService } from '../service/errorService'
import { MobilePreviewFallbackFonts } from '../service/fontsService'
import {
  type GrabEvent,
  type PreviewDeeplinkElement,
  type PreviewGrabData,
  type PreviewNavBarContent,
  type PreviewPageContent,
  type PreviewPageElement,
} from '../types/applicationData.type'

export type GettersAppData = GettersObj<typeof mapGettersAppData>

export class AppDataState {
  data: PreviewGrabData | null = null
}

function adaptMenuElement(
  serverMenuElement?: MenuElement,
): PreviewDeeplinkElement | undefined {
  if (!serverMenuElement) {
    return undefined
  }
  return {
    ...serverMenuElement,
    title: serverMenuElement.titles.fr,
  }
}

function adaptPageElement(serverPageElement: PageElement): PreviewPageElement {
  return {
    ...serverPageElement,
    title: serverPageElement.titles.fr,
  }
}

function adaptPageContent(
  serverPageContent: PageContent,
  rootState: RootState,
): PreviewPageContent {
  if (
    serverPageContent.type === 'HOME_CUSTOM_FULL' ||
    serverPageContent.type === 'HOME_CUSTOM_THIRD' ||
    serverPageContent.type === 'HOME_CUSTOM_HALF' ||
    serverPageContent.type === 'PAGE_LIST'
  ) {
    return {
      ...serverPageContent,
      title: serverPageContent.title?.fr,
      elements: serverPageContent.elements.map((e) => e && adaptPageElement(e)),
    }
  } else if (
    serverPageContent.type === 'HOME_PROGRAMS' ||
    serverPageContent.type === 'HOME_LIVE'
  ) {
    return {
      ...serverPageContent,
      title: serverPageContent.title && serverPageContent.title.fr,
    }
  } else if (serverPageContent.type === 'HOME_IMAGE') {
    const { mainImageTemp } = rootState.pageSetup
    return {
      ...serverPageContent,
      image: mainImageTemp
        ? { previewDataUri: mainImageTemp }
        : serverPageContent.image,
      id: serverPageContent.id,
    }
  }
  if (serverPageContent.type === 'HOME_SUB_IMAGE') {
    const { subImageTemp } = rootState.pageSetup
    return {
      ...serverPageContent,
      image: subImageTemp
        ? { previewDataUri: subImageTemp }
        : serverPageContent.image,
    }
  } else if (serverPageContent.type === 'HOME_COUNTDOWN') {
    return {
      ...serverPageContent,
      title: serverPageContent.title && serverPageContent.title.fr,
      dateParam: new Date(serverPageContent.dateParam!).getTime(),
    }
  }
  return { ...serverPageContent }
}

export const {
  appData,
  commit: commitAppData,
  dispatch: dispatchAppData,
  mapGetters: mapGettersAppData,
  mapState: mapStateAppData,
  useGetter: useGetterAppData,
  useState: useStateAppData,
} = createStore({
  namespaced: true,
  moduleName: 'appData',
  initState: new AppDataState(),
  mutations: {
    SET_INITIAL_STATE(state: AppDataState, initialGrab: PreviewGrabData) {
      state.data = initialGrab
    },
  },
  getters: {
    getGrabData: (state, getters, rootState): PreviewGrabData | null => {
      if (!state.data) return null

      const newNavBar = rootState.menuEntries.navBar
      const newPageContents = rootState.pageSetup.pageContents

      const adaptedState: PreviewGrabData = {
        ...state.data,
        applicationConfig: {
          ...state.data.applicationConfig,
          fontTitle:
            state.data.applicationConfig.fontTitle ??
            MobilePreviewFallbackFonts.fontTitle.fontName,
          fontTextBold:
            state.data.applicationConfig.fontTextBold ??
            MobilePreviewFallbackFonts.fontTextBold.fontName,
          fontTextRegular:
            state.data.applicationConfig.fontTextRegular ??
            MobilePreviewFallbackFonts.fontTextRegular.fontName,
          fontTextSemiBold:
            state.data.applicationConfig.fontTextSemiBold ??
            MobilePreviewFallbackFonts.fontTextSemiBold.fontName,
        },
      }
      if (newNavBar) {
        adaptedState.navBar = {
          ...newNavBar,
          elements: newNavBar.elements.map(
            adaptMenuElement,
          ) as PreviewNavBarContent['elements'],
        }
      }
      if (newPageContents && newPageContents.length) {
        const newContents = newPageContents.map((val) =>
          adaptPageContent(val as PageContent, rootState),
        )
        if (newContents[0]?.page === 'MENU_PAGE') {
          adaptedState.menuContents = newContents
        } else if (newContents[0]?.page === 'HOME_PAGE') {
          adaptedState.homeContents = newContents
        }
      }

      return adaptedState
    },

    getRandomEvents: (state): GrabEvent[] => {
      if (!state.data) return []
      return state.data.events.filter((e, i) => i < 3)
    },
  },
  actions: {
    async loadCurrentData(
      { commit },
      payload: { filterOnlyVisible: boolean },
    ): Promise<void> {
      try {
        const initialGrab = await ApplicationDataService.getCurrent(
          'fr',
          payload.filterOnlyVisible,
        )
        commit('SET_INITIAL_STATE', initialGrab)
        await dispatchMenuEntries('getAllContent')
      } catch (e) {
        ErrorService.handleError(e, false)
      }
    },
  },
})
